










































































import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import FroalaEditor from '@/components/FroalaEditor.vue';
import { Option, Select } from 'element-ui';
import {
  HOST,
  Faq,
  FaqConnection,
  FaqParam,
  FaqTypeInfo,
  SERVICE,
} from '@/domain/faqs/model/Faq';
import { FAQ, FAQ_CATEGORY_LIST } from '@/domain/faqs/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { FaqService } from '@/domain/faqs/service/FaqService';
import { apolloClient } from '@/apolloClient';

const faqService = new FaqService(apolloClient);
export default Vue.extend({
  name: 'FaqDetailContainer',
  components: {
    FroalaEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
  },
  props: {
    id: String,
    forHostOnly: String,
  },
  data(): {
    categories: FaqTypeInfo[];
    filter: { id: string; forHostOnly: boolean };
    faq: Faq;
    loading: boolean;
  } {
    return {
      categories: [],
      filter: { id: '', forHostOnly: false },
      faq: {
        question: '',
        answer: '',
        type: '',
        sort: 0,
      } as Faq,
      loading: true,
    };
  },
  created() {
    //id값이 없을 경우는 query skip
    if (!this.id) {
      this.$apollo.queries.faqs.skip = true;
      this.$data.loading = false;
    }

    this.$data.filter.id = this.id;
    this.$data.filter.forHostOnly = this.forHostOnly
      ? JSON.parse(this.forHostOnly)
      : true;
  },
  methods: {
    async saveFaq(): Promise<void | boolean> {
      if (
        this.$data.faq.content === '' ||
        this.$data.faq.question === '' ||
        !this.$data.faq.type
      ) {
        this.$modal.show({
          title: '자주 묻는 질문 등록 오류!',
          type: 'warning',
          message: '필수 값을 등록해주세요.',
        });

        return false;
      }

      const param: FaqParam = {
        question: this.$data.faq.question,
        answer: this.$data.faq.answer,
        type: this.$data.faq.type,
        forHostOnly: this.$data.filter.forHostOnly,
        sort: this.$data.faq.sort,
      };

      this.$modal.show(
        {
          title: '자주 묻는 질문 저장!',
          type: 'info',
          message: '자주 묻는 질문을 저장하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await faqService.saveFaq(param);

            this.$modal.show(
              {
                title: '자주 묻는 질문 등록 완료',
                type: 'success',
                message: '자주 묻는 질문 등록이 완료되었습니다.',
              },
              () => {
                (this as any).redirectPage();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '자주 묻는 질문 저장 실패!',
              type: 'warning',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async updateFaq(): Promise<void | boolean> {
      const param: FaqParam = {
        id: this.$data.faq.id,
        question: this.$data.faq.question,
        answer: this.$data.faq.answer,
        type: this.$data.faq.type,
        forHostOnly: this.$data.filter.forHostOnly,
        sort: this.$data.faq.sort,
      };

      this.$modal.show(
        {
          title: '자주 묻는 질문 수정!',
          type: 'info',
          message: '자주 묻는 질문을 수정하시 겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await faqService.updateFaq(param);

            this.$modal.show(
              {
                title: '자주 묻는 질문 수정 완료',
                type: 'success',
                message: '자주 묻는 질문 수정이 완료되었습니다.',
              },
              () => {
                (this as any).redirectPage();
              }
            );
          } catch (err) {
            console.log(err);
            this.$modal.show({
              title: '자주 묻는 질문 수정 실패!',
              type: 'warning',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async deleteFaq(id: number): Promise<void> {
      this.$modal.show(
        {
          title: '자주 묻는 질문 삭제!',
          type: 'info',
          message: '자주 묻는 질문을 삭제 하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await faqService.deleteFaq(id);

            this.$modal.show(
              {
                title: '삭제 완료!',
                type: 'success',
                message: '삭제가 완료 되었습니다.',
              },
              () => {
                (this as any).redirectPage();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '삭제 실패!',
              type: 'warning',
              message: '삭제 실패했습니다.',
            });
          }
        }
      );
    },
    redirectPage(): void {
      const typeName = this.$data.filter.forHostOnly
        ? HOST.toLowerCase()
        : SERVICE.toLowerCase();
      this.$router.push('/faqs/list/' + typeName);
    },
  },
  apollo: {
    faqs: {
      query: FAQ,
      variables() {
        return {
          filter: this.$data.filter,
          page: 1,
          size: 1,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ board: { faqs: FaqConnection } }>) {
        this.$data.faq = result.data.board.faqs.edges[0].node;
        this.$data.loading = false;
      },
      update: data => {
        return data.board.faqs.edges[0].node;
      },
    },
    faqTypes: {
      query: FAQ_CATEGORY_LIST,
      variables() {
        return {
          param: { forHostOnly: this.$data.filter.forHostOnly },
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ board: { faqTypes: any[] } }>) {
        this.$data.categories = result.data.board.faqTypes;
      },
      update: data => {
        return data.board.faqTypes;
      },
    },
  },
});
