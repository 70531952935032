import gql from 'graphql-tag';

export const REGISTER_FAQ = gql`
  mutation ($param: FaqParam!) {
    createFaq(param: $param) {
      id
      question
      answer
      type
      createdAt
      sort
    }
  }
`;

export const MODIFIED_FAQ = gql`
  mutation ($param: FaqParam!) {
    updateFaq(param: $param) {
      id
      question
      answer
      type
      createdAt
      sort
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation ($id: ID!) {
    deleteFaq(id: $id) {
      success
      message
    }
  }
`;
