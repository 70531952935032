import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  DELETE_FAQ,
  MODIFIED_FAQ,
  REGISTER_FAQ,
} from '@/domain/faqs/queries/mutation';
import { Faq, FaqParam, Result } from '@/domain/faqs/model/Faq';

export class FaqService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async saveFaq(param: FaqParam): Promise<Faq> {
    const response = await this.apollo.mutate({
      mutation: REGISTER_FAQ,
      variables: {
        param: {
          question: param.question,
          answer: param.answer,
          type: param.type,
          forHostOnly: param.forHostOnly,
          sort: param.sort,
        },
      },
    });

    return response.data.registerFaq;
  }

  async deleteFaq(id: number): Promise<Result> {
    const response = await this.apollo.mutate({
      mutation: DELETE_FAQ,
      variables: { id: id },
    });

    return response.data.deleteFaq;
  }

  async updateFaq(param: FaqParam): Promise<Faq> {
    const response = await this.apollo.mutate({
      mutation: MODIFIED_FAQ,
      variables: {
        param: {
          id: param.id,
          question: param.question,
          answer: param.answer,
          type: param.type,
          forHostOnly: param.forHostOnly,
          sort: param.sort,
        },
      },
    });

    return response.data.updateFaq;
  }
}
