import { CommonState, PageInfo } from '@frientrip/domain';

export const HOST = 'HOST';
export const SERVICE = 'SERVICE';

export interface Faq {
  id: number;
  question: string;
  answer: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  forHostOnly: boolean;
  status: CommonState;
  sort: number;
}

export type CategoryType = {
  [index: string]: string;
  // 일반 사용자 FAQ용
  SERVICE_HELP: string;
  SERVICE_CUSTOMER: string;
  SERVICE_PAYMENT: string;
  SERVICE_COUPON: string;
  SERVICE_HOST: string;
  SERVICE_ETC: string;
  // 호스트 FAQ용
  HOST_COMMON: string;
  HOST_MAKE_FRIP: string;
  HOST_BEFORE_OPEN: string;
  HOST_AFTER_OPEN: string;
  HOST_BILL: string;
  HOST_ETC: string;
};

export const FaqType: CategoryType = {
  // 일반 사용자 FAQ용
  SERVICE_HELP: '이용안내',
  SERVICE_CUSTOMER: '회원정보',
  SERVICE_PAYMENT: '결재/환불',
  SERVICE_COUPON: '쿠폰/에너지',
  SERVICE_HOST: '호스트 신청',
  SERVICE_ETC: '기타',
  // 호스트 FAQ용
  HOST_COMMON: '일반',
  HOST_MAKE_FRIP: '등록',
  HOST_BEFORE_OPEN: '오픈전',
  HOST_AFTER_OPEN: '오픈후',
  HOST_BILL: '정산',
  HOST_ETC: '기타',
};

export interface FaqParam {
  id?: number;
  question: string;
  answer: string;
  type: string;
  forHostOnly: boolean;
  sort?: number;
}

export interface FaqFilter {
  id?: number;
  questionLike?: string;
  type?: string;
  forHostOnly: boolean;
}

export interface FaqConnection {
  edges: FaqEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface FaqEdge {
  node: Faq;
  cursor: string;
}

export interface FaqTypeInfo {
  label: string;
  value: string;
}
export interface FaqTypeParam {
  forHostOnly: boolean;
}

export interface Result {
  success: boolean;
  message: string;
}

export const FaqTypeLabel: { label: string; type: string }[] = [
  {
    label: FaqType.HOST_FAQ,
    type: 'primary',
  },
  {
    label: FaqType.HOST_COMMON,
    type: 'success',
  },
  {
    label: FaqType.HOST_EVENT,
    type: 'info',
  },
];
